// Imports
// ------
import React from 'react';
import Header from '@parts/Header';
import NotFound from '@parts/NotFound';

// Component
// ------
const NotFoundPage = () => {
    return (
        <>
            <Header />
            
            <NotFound />
        </>
    )
}

export default NotFoundPage;