// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import Marquee from 'react-double-marquee';
import { Row, Column } from '@waffl';

// Styles
// ------
import {
    Jacket,
    MarqueeJacket,
    Title,
    Text,
    ButtonJacket
} from './styles';

// Component
// ------
const NotFound = () => (
    <Jacket fullscreen>
        <Row isExpanded isCollapsed isCenter>
            <Column small={12}>
                <MarqueeJacket>
                    <Marquee direction="left" speed={0.05}>
                        <Title>404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - 404 - </Title>
                    </Marquee>
                </MarqueeJacket>
                <MarqueeJacket>
                    <Marquee direction="left" speed={0.02}>
                        <Text>The page you're looking for was not found <span>-</span> The page you're looking for was not found <span>-</span> The page you're looking for was not found <span>-</span> The page you're looking for was not found <span>-</span> The page you're looking for was not found <span>-</span></Text>
                    </Marquee>
                </MarqueeJacket>
            </Column>
        </Row>

        <ButtonJacket>
            <Button type="link" to="/" extraPad="true">Back to Home</Button>
        </ButtonJacket>
    </Jacket>
);

export default NotFound;