// Imports
// ------
import styled, { css } from 'styled-components';
import { Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)``;

export const MarqueeJacket = styled.div`
    transition: all 1s ease;
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-y: visible;
`;

export const Title = styled.div(props => css`
    display: inline-block;

    font-family: ${props.theme.heading};
    font-size: 12vh;
    font-weight: 200;
`);

export const Text = styled.p(props => css`
    display: inline-block;
    margin-top: 2rem;

    font-family: ${props.theme.body};
    font-size: 1.4rem;
    text-transform: uppercase;

    span {
        display: inline-block;
        margin: 0 2.4rem;
    }
`);

export const ButtonJacket = styled.div`
    position: absolute;
    bottom: 4rem; left: 50%;
    transform: translateX(-50%);
    width: max-content;
`;